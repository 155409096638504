import React from 'react'
import { Link } from 'gatsby'

import { SUBSCRIPTION, LICENSE } from '../../constants/constants'
import './GetStartedList.scss'

const PCsImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/PCs.png'
const HeadsetPluggingIn_DesktopImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/HeadsetPluggingIn_Desktop.png'
const HeadsetPluggingIn_MobileImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/HeadsetPluggingIn_Mobile.png'
const DownloadOvationTrialImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/DownloadOvationTrial.png'
const DownloadOvationTrialImage2 = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/DownloadOvationTrial2.png'
const User1Image = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/User1.png'
const User2Image = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/User2.png'
const MailImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/Mail.png'
const SubscribeImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/SubscribeIcon.png'
const SignUpImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/SignUp.png'
const OvationQuestImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/OvationQuest.png'
const OvationDesktopImage = 'https://d1syj4d8txnu77.cloudfront.net/OvationDesktop/OvationForDesktop.png'
// const UserMonitorAndVRImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/UserMonitorAndVR.png'
const UserMonitorAndVRImage = 'https://d1syj4d8txnu77.cloudfront.net/GetStarted/PodiumSuccess.png'

const GetStartedList = ({ subscription, license, onChange }) => {
  const clickedHelpDeploying = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-site-link-contact-help-deploying'
    })
  }

  const goToViveProPage = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-site-link-htc-vive-pro-eye'
    })
  }

  const goToOmniceptPage = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-site-link-hp-omnicept'
    })
  }

  const goToTrialStartPage = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-site-link-trial-marketing-organization-start-your-free-trial'
    })
  }

  const renderIndividual = () => (
    <div>
      {/* {license === LICENSE.VR && (
        <div className="get-started-list-row">
          <div className="row">
            <div className="col-xs-6 col-md-1 order-md-1">
              <div className="get-started-list-number">1.</div>
            </div>
            <div className="col-xs-6 col-md-7 ml-md-auto order-md-3">
              <div className="get-started-list-subtitle">
                <h2>Purchase (or already own) a VR-Ready PC. In other words, a powerful computer.</h2>
              </div>
              <h4>
                Windows only (VR is not compatible with Mac).
                <br />
                More details in our{' '}
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://docs.ovationvr.com/ovation/hardware"
                  className="gtm-outbound-link-documentation-get-started-hardware"
                >
                  hardware documentation
                </a>
                .
              </h4>
            </div>
            <div className="col-md-4 order-md-2">
              <div className="get-started-list-image-wrap">
                <img src={PCsImage} />
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* {license === LICENSE.VR && (
        <div className="get-started-list-row">
          <div className="row">
            <div className="col-xs-6 col-md-1 order-md-1">
              <div className="get-started-list-number">2.</div>
            </div>
            <div className="col-xs-6 col-md-7 order-md-3 get-started-list-z-index-wrap">
              <div className="get-started-list-subtitle">
                <h2>Purchase (or already own) a VR Headset that connects to a PC.</h2>
              </div>
              <h4>
                Due to the processing power needed for Ovation's hyper-realistic environments and extensive performance
                analytics, standalone and mobile headsets are not compatible.
                <br />
                Exception: Oculus Quest with Link / AirLink.
              </h4>
              <div className="get-started-list-recommendation-title">We recommend:</div>
              <div className="get-started-list-recommendation-body">
                <ul>
                  <li className="get-started-list-recommendation-item">
                    <Link to="/omnicept" onClick={goToOmniceptPage}>
                      HP Reverb G2 Omnicept Edition
                    </Link>
                  </li>
                  <li className="get-started-list-recommendation-item">
                    <Link to="/vive-pro-eye" onClick={goToViveProPage}>
                      HTC Vive Pro Eye
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 order-md-2">
              <div className="get-started-list-image-wrap d-none d-md-block">
                <img src={HeadsetPluggingIn_DesktopImage} />
              </div>
              <div className="get-started-list-image-wrap d-md-none" style={{ marginTop: '-7.85em' }}>
                <img src={HeadsetPluggingIn_MobileImage} />
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div className="get-started-list-row">
        <div>
          <div className="row">
            <div className="col-xs-6 col-md-1 order-md-1">
              <div className="get-started-list-number">1.</div>
            </div>
            <div className="col-xs-6 col-md-7 mr-auto order-md-3">
              <div className="get-started-list-subtitle">
                <h2>Sign up for an Ovation account and activate a 7-day free trial</h2>
              </div>
              <h4>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://app.prod.ovationvr.com/signup"
                  className="gtm-outbound-link-signup"
                >
                  Sign up here
                </a>
                . Trial available to first-time subscribers. Credit card required.
              </h4>
              {/* <h4>
                Trial activation requires a credit card. You will not be charged until the trial period has ended and
                you can cancel the trial at any time.
                <br />
                <br />
                Unsure if your computer is powerful enough for Ovation? Check the minimum specifications in our{' '}
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://docs.ovationvr.com/ovation/hardware"
                  className="gtm-outbound-link-documentation-get-started-hardware"
                >
                  hardware documentation
                </a>
                , or download Ovation and evaluate performance during the trial period.
              </h4> */}
            </div>
            <div className="col-md-4 order-md-2">
              <div className="get-started-list-image-wrap">
                {/* <img src={license === LICENSE.VR ? DownloadOvationTrialImage : DownloadOvationTrialImage2} /> */}
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://app.prod.ovationvr.com/signup"
                  className="gtm-outbound-link-signup"
                >
                  <img src={SignUpImage} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="get-started-list-row" style={{ marginBottom: '0' }}>
        <div>
          <div className="row">
            <div className="col-xs-6 col-md-1 order-md-1">
              <div className="get-started-list-number">2.</div>
            </div>
            <div className="col-xs-6 col-md-7 mr-auto order-md-3">
              <div className="get-started-list-subtitle">
                <h2>Install Ovation on your {license === LICENSE.VR ? 'VR headset' : 'computer'}</h2>
              </div>
              {license === LICENSE.VR ? (
                <h4>
                  Visit{' '}
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.oculus.com/experiences/quest/4510688418969375/"
                    className="gtm-outbound-link-ovation-meta-app-store"
                  >
                    Ovation's Meta App Store page
                  </a>{' '}
                  while signed into your Meta account to add Ovation to your App Library.
                  <br />
                  <br />
                  Ovation is only compatible with Meta Quest 2, Quest 3, and Quest Pro headsets.
                </h4>
              ) : (
                <h4>
                  Visit the Downloads page once signed in with your new Ovation account. Ovation for Monitors requires a
                  Windows PC.
                </h4>
              )}
            </div>
            <div className="col-md-4 order-md-2">
              <div className="get-started-list-image-wrap">
                {/* <img src={license === LICENSE.VR ? User2Image : User1Image} /> */}
                <img src={license === LICENSE.VR ? OvationQuestImage : OvationDesktopImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const renderOrganization = () => (
    <div>
      <div className="get-started-list-row">
        <div className="row">
          <div className="col-xs-6 col-md-1 order-md-1">
            <div className="get-started-list-number">1.</div>
          </div>
          <div className="col-xs-6 col-md-7 ml-md-auto order-md-3">
            <div className="get-started-list-subtitle">
              <h2>
                Submit a{' '}
                <Link to="/trial/organization" onClick={goToTrialStartPage}>
                  contact form{' '}
                </Link>
                so we can discuss your organization's needs.
              </h2>
            </div>
            <h4>
              We've helped many of our customers get started from scratch with virtual reality. We'd love to help you
              too.
            </h4>
            <h4>
              An Ovation staff member will respond quickly to answer any questions and recommend necessary hardware.
            </h4>
          </div>
          <div className="col-md-4 order-md-2">
            <div className="get-started-list-image-wrap">
              <img src={MailImage} style={{ paddingRight: '1em' }} />
            </div>
          </div>
        </div>
      </div>

      <div className="get-started-list-row">
        <div className="row">
          <div className="col-xs-6 col-md-1 order-md-1">
            <div className="get-started-list-number">2.</div>
          </div>
          <div className="col-xs-6 col-md-7 order-md-3 get-started-list-z-index-wrap">
            <div className="get-started-list-subtitle">
              <h2>When you are ready to deploy Ovation, we'll ensure you are set up for success.</h2>
            </div>
            <h4>
              You'll have our&nbsp;
              <a
                target="_blank"
                rel="noopener"
                href="https://docs.ovationvr.com/ovation/instructions/organization-information-packet"
                className="gtm-outbound-link-oculus-rift-s"
              >
                Organization Information Packet
              </a>{' '}
              as a step-by-step guide, and our staff available when you need us.
            </h4>
          </div>
          <div className="col-md-4 order-md-2">
            <div className="get-started-list-image-wrap d-none d-md-block">
              <img src={UserMonitorAndVRImage} />
            </div>
            <div className="get-started-list-image-wrap d-md-none">
              <img src={UserMonitorAndVRImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="get-started-list-wrap">
      <div className="wrapper">
        <div className="get-started-mode">
          <div className="get-started-mode-column">
            <div className="get-started-mode-title">Select Subscription Type:</div>
            <button
              type="button"
              className={subscription === SUBSCRIPTION.INDIVIDUAL ? 'active' : ''}
              onClick={() => onChange({ subscription: SUBSCRIPTION.INDIVIDUAL })}
            >
              Individual
            </button>
            <button
              type="button"
              className={subscription === SUBSCRIPTION.ORGANIZATION ? 'active' : ''}
              onClick={() => onChange({ subscription: SUBSCRIPTION.ORGANIZATION })}
            >
              Organization
            </button>
          </div>
          {subscription === SUBSCRIPTION.INDIVIDUAL && (
            <div className="get-started-mode-column">
              <div className="get-started-mode-title">Select Mode:</div>
              <button
                type="button"
                className={license === LICENSE.VR ? 'active' : ''}
                onClick={() => onChange({ license: LICENSE.VR })}
              >
                VR
              </button>
              <button
                type="button"
                className={license === LICENSE.MONITOR ? 'active' : ''}
                onClick={() => onChange({ license: LICENSE.MONITOR })}
              >
                Monitor
              </button>
            </div>
          )}
        </div>
        <div className="get-started-list-title">
          <h2>How to Get Started</h2>
        </div>

        {subscription === SUBSCRIPTION.INDIVIDUAL ? renderIndividual() : renderOrganization()}
      </div>
    </div>
  )
}

export default GetStartedList
